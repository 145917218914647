import React from 'react';
import './index.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import VoiceBot from './pages/VoiceBot'; // Adjust the path to your component
// import VoiceBot from './components/VoiceBot'; // Adjust the path to your component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ThemeProvider } from './contexts/ThemeContext';


const App = () => {
  return (
    // <ThemeProvider>
    <>
      <VoiceBot />

      <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </>
    //  </ThemeProvider>
  )
}

export default App
