import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { IoArrowUp, IoClose } from "react-icons/io5";
import { maxMessageLength } from '../../config/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Chat = ({ setMessages, isWaitingForResponse, setIsWaitingForResponse, setIsTyping, setAbortController }) => {
  const [input, setInput] = useState("");
  const [theme, setTheme] = useState("light");
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: ""
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: ""
  });


  const nameRegex = /^[a-zA-Z\s]{2,50}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (input.trim() === "" || isWaitingForResponse) return;
  
    const truncatedInput = input.slice(0, maxMessageLength);
    
    const newMessage = {
      type: "user",
      content: truncatedInput,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");
    setIsTyping(true);
    setIsWaitingForResponse(true);
  
    const controller = new AbortController();
    setAbortController(controller);

    try {
      const response = await axios.post("https://poc-python-chatbot-api.onrender.com/chat",
        { text: truncatedInput },
        {
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal
        }
      );
  
      if (response.status === 200) {
        const botResponse = {
          type: "bot",
          content: response.data,
          timestamp: new Date(),
        };
        console.log('Bot response:', response.data);
        setMessages((prevMessages) => [...prevMessages, botResponse]);
      } else {
        console.error('Error from API:', response.status);
 
      }
    } catch (error) {
    
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error sending message to backend:', error);
        const errorMessage = {
            type: "bot",
            content: "Sorry, I encountered an error. Please try again or call us @ +91 9035163809",
            timestamp: new Date(),
          };
          setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    } finally {
      setIsTyping(false);
      setIsWaitingForResponse(false);
      setAbortController(null);
    }
  }, [input, maxMessageLength, setIsWaitingForResponse]);

  const toggleForm = () => {
    setShowForm(!showForm);
    // Reset form data and errors when toggling form
    setFormData({ name: "", email: "", phone: "" });
    setFormErrors({ name: "", email: "", phone: "" });
  };
 
  const cancelForm = () => {
    setShowForm(false);
    setFormData({ name: "", email: "", phone: "" });
    setFormErrors({ name: "", email: "", phone: "" });
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!nameRegex.test(value)) {
          error = "Name should be 2-50 characters long and contain only letters and spaces.";
        }
        break;
      case "email":
        if (!emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;
      case "phone":
        if (!phoneRegex.test(value)) {
          error = "Please enter a valid phone number (e.g., +1234567890).";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: validateField(name, value)
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const errors = {
      name: validateField("name", formData.name),
      email: validateField("email", formData.email),
      phone: validateField("phone", formData.phone)
    };
    setFormErrors(errors);
  
    if (Object.values(errors).some(error => error !== "")) {
      toast.error("Please correct the errors in the form.");
      return;
    }
  
    try {
      let data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.phone,
      };
  
      const response = await axios.post(
        "https://bolofy.com/api/botCallLog/bot-lead-capture",
        data,
        {
          headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva',
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            type: "bot",
            content: "Your form has been submitted successfully!",
            timestamp: new Date()
          }
        ]);
        toast.success("Form submitted successfully!");
        setShowForm(false);
        setFormData({ name: "", email: "", phone: "" });
        setFormErrors({ name: "", email: "", phone: "" });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      toast.error("Error submitting form. Please try again.");
    }
  };

  const formVariants = {
    hidden: { opacity: 0, height: 0, transition: { duration: 0.3, ease: "easeInOut" } },
    visible: { opacity: 1, height: "auto", transition: { duration: 0.3, ease: "easeInOut" } },
  };
  
  const inputVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeInOut" } },
  };

  const remainingChars = maxMessageLength - input.length;

  return (
    <div className={`p-2 rounded-b-lg ${theme === "dark" ? "bg-gray-800 text-black" : "bg-white text-gray-800"}`}>
      <AnimatePresence>
        {showForm && (
          <motion.form
            variants={formVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onSubmit={handleFormSubmit}
            className={`mb-2 p-2 ${theme === "dark" ? "bg-gray-700" : "bg-gray-100"} rounded-lg relative`}
          >
            <motion.button
              type="button"
              onClick={cancelForm}
              className={`absolute -top-8 right-2  ${theme === "dark" ? "bg-gray-700 text-gray-400 hover:text-[#2888b5]" : "bg-gray-200 text-gray-500 hover:bg-gray-500 hover:text-[#2888b5]"}  rounded-full  hover:bg-opacity-20`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <IoClose size={20} />
            </motion.button>
            <motion.div variants={inputVariants}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                placeholder="Name"
                className={`w-full mb-2 p-2 ${theme === "dark" ? "bg-gray-600 text-white" : "bg-white text-gray-800"} rounded ${formErrors.name ? 'border-red-500' : ''}`}
                required
              />
              {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
            </motion.div>
            <motion.div variants={inputVariants}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                placeholder="Email ID"
                className={`w-full mb-2 p-2 ${theme === "dark" ? "bg-gray-600 text-white" : "bg-white text-gray-800"} rounded ${formErrors.email ? 'border-red-500' : ''}`}
                required
              />
              {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
            </motion.div>
            <motion.div variants={inputVariants}>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
                placeholder="Phone Number"
                className={`w-full mb-2 p-2 ${theme === "dark" ? "bg-gray-600 text-white" : "bg-white text-gray-800"} rounded ${formErrors.phone ? 'border-red-500' : ''}`}
                required
              />
              {formErrors.phone && <p className="text-red-500 text-xs mt-1">{formErrors.phone}</p>}
            </motion.div>
            <motion.button
              type="submit"
              className={`w-full p-2 bg-[#2888B5] text-white rounded hover:bg-opacity-90`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Submit
            </motion.button>
          </motion.form>
        )}
      </AnimatePresence>

      {!showForm && (
        <AnimatePresence>
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            onClick={toggleForm}
            className={`w-24 mb-2 ml-2 p-2 ${theme === "dark" ? "bg-[#2888b542] text-white hover:bg-[#2888b5]" : "bg-gray-200 text-gray-800 hover:bg-[#2888b542]"} rounded-3xl flex justify-center items-center`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <span>Contact us</span>
          </motion.button>
        </AnimatePresence>
      )}

      <form onSubmit={handleSubmit} className={`p-2 rounded-b-lg ${theme === "dark" ? "bg-gray-800 text-black" : "bg-white text-gray-800"}`}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="relative mb-2"
        >
          <motion.div
            whileHover={{ boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.5)" }}
            className={`flex items-center ${theme === "dark" ? "bg-gray-700 shadow-md shadow-slate-900" : "bg-white shadow-md"} rounded-full overflow-hidden`}
          >
            <motion.input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value.slice(0, maxMessageLength))}
              className={`flex-grow p-3 pl-5 pr-8 ${theme === "dark" ? "text-white" : "text-gray-700"} bg-transparent focus:outline-none text-sm ${isWaitingForResponse ? "cursor-not-allowed" : ""}`}
              placeholder="Ask me anything..."
              whileFocus={{ scale: 1.02 }}
              transition={{ duration: 0.2 }}
              disabled={isWaitingForResponse}
              autoFocus
            />
            <div className="absolute right-12 top-8 transform -translate-y-1/2 text-xs text-gray-400">
              {remainingChars}
            </div>
            <motion.button
              type="submit"
              disabled={input.trim() === "" || isWaitingForResponse}
              className={`${
                input.trim() === "" || isWaitingForResponse
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-[#2888b581] hover:bg-[#2888b5]"
              } text-white p-3 rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400 transition-all duration-300`}
            >
              <IoArrowUp size={20} />
            </motion.button>
          </motion.div>
        </motion.div>
      </form>
    </div>
  );
};

export default Chat;