import React, {useState} from 'react';
import { motion } from 'framer-motion';

const LoadingSpinner = () => {
  const [theme, setTheme] = useState("light");
  
  const dotVariants = {
    animate: (i) => ({
      scale: [0.5, 1, 0.5],
      transition: {
        duration: 0.8,
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut",
        delay: i * 0.2,
      },
    }),
  };
  
  return (
    <div className="flex items-center">
        <span className={`mr-2 text-sm font-medium  ${theme === "dark" ? "text-gray-100" : "text-gray-800"}`}>Thinking</span>
        <div className="flex space-x-1">
          {[0, 1, 2].map((i) => (
            <motion.div
              key={i}
              variants={dotVariants}
              animate="animate"
              custom={i}
              className={`w-1 h-1 mt-2  ${theme === "dark" ? "bg-blue-300" : "bg-blue-600"} rounded-full`}
            />
          ))}
        </div>
      </div>
  );
};

export default LoadingSpinner;