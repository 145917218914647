import React from 'react';
import { motion } from 'framer-motion';

const SpectrumAnimation = () => {
  return (
    <div className="flex items-center space-x-1">
      {[...Array(5)].map((_, index) => (
        <motion.div
          key={index}
          animate={{
            scaleY: [1, 1.5, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: index * 0.1,
          }}
          className="w-1 h-4 bg-blue-800 rounded-full"
        />
      ))}
    </div>
  );
};

export default SpectrumAnimation;