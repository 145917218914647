// Configuration constants

export const maxMessageLength = 1000;
export const maxRecordingDuration = 15;

export const apiEndpoints = {
  chat: "https://3516-49-36-67-16.ngrok-free.app/chat",
  generateAudio: "https://3516-49-36-67-16.ngrok-free.app/generate_audio",
  leadCapture: "https://bolofy.com/api/botCallLog/bot-lead-capture"
};

export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva";

export const validationRegex = {
  name: /^[a-zA-Z\s]{2,50}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^\+?[1-9]\d{1,14}$/
};