import React, { useState, useRef, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as RiIcons from "react-icons/ri";
import { MdMessage } from "react-icons/md";

import Talk from '../components/Talk/Talk';
import Chat from '../components/Chat/Chat';
import LoadingSpinner from '../components/common/LoadingSpinner';
import AnimatedButtonLayout from '../components/common/AnimatedButtonLayout';
import SpectrumAnimation from '../components/common/SpectrumAnimation';
// import { useTheme, ThemeProvider } from '../contexts/ThemeContext';

const VoiceBot = () => {
    const [isInChat, setIsInChat] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [recordingState, setRecordingState] = useState("Initial");
    const [recordingDuration, setRecordingDuration] = useState(0);
    const [showTalk, setShowTalk] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [currentSpeech, setCurrentSpeech] = useState(null);
    const [playingMessageId, setPlayingMessageId] = useState(null);

    const [speechDetected, setSpeechDetected] = useState(false);
    const [messages, setMessages] = useState([
        {
            type: "bot",
            content: "How can I help you?",
            timestamp: new Date(),
        },
    ]);
    const [isTyping, setIsTyping] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const chatBoxRef = useRef(null);
    const [theme, setTheme] = useState("light");
    const [isLoading, setIsLoading] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [abortController, setAbortController] = useState(null);
    const [activeSection, setActiveSection] = useState("Initial");

    const toggleTheme = useCallback(() => {
        setTheme((prevTheme) => prevTheme === "light" ? "dark" : "light");
    }, []);

    const toggleChat = useCallback(() => {
        setIsChatOpen((prevState) => !prevState);
    }, []);

    const handleToggleSpeech = useCallback((text, messageId) => {
        if (playingMessageId === messageId) {
            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.pause();
                setPlayingMessageId(null);
            } else {
                window.speechSynthesis.resume();
                setPlayingMessageId(messageId);
            }
        } else {
            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
            }
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.onstart = () => {
                setPlayingMessageId(messageId);
            };
            utterance.onend = () => {
                setPlayingMessageId(null);
            };
            utterance.onpause = () => setPlayingMessageId(null);
            utterance.onresume = () => setPlayingMessageId(messageId);
            window.speechSynthesis.speak(utterance);
            setCurrentSpeech({ utterance, messageId });
        }
    }, []);



    //   const speakText = useCallback((text, messageId) => {
    //     const utterance = new SpeechSynthesisUtterance(text);
        
    //     utterance.onstart = () => {
    //       setIsPlaying(true);
    //       setCurrentSpeech({ utterance, messageId });
    //     };
   
    //     utterance.onend = () => {
    //       setIsPlaying(false);
    //       setCurrentSpeech(null);
    //     };
    
    //     utterance.onpause = () => setIsPlaying(false);
    //     utterance.onresume = () => setIsPlaying(true);
    
    //     window.speechSynthesis.speak(utterance);
    //     console.log('utterance', utterance);
    //   }, []);
      
    const getWelcomeMessage = (section) => {
        switch (section) {
            case "chat":
                return "Welcome to the chat! How can I assist you today?";
            case "talk":
                return "Welcome to the talk section! You can speak your message.";
            default:
                return "How can I help you?";
        }
    };

    const handleChatClick = useCallback(() => {
        setShowChat(true);
        setShowTalk(false);
        setIsInChat(true);
        setActiveSection("chat");
        setMessages([
            { type: "bot", content: getWelcomeMessage("chat"), timestamp: new Date() }
        ]);
    }, []);

    const handleTalkClick = useCallback(() => {
        setShowTalk(true);
        setShowChat(false);
        setIsInChat(true);
        setActiveSection("talk");
        setMessages([
            { type: "bot", content: getWelcomeMessage("talk"), timestamp: new Date() }
        ]);
    }, []);

    const cancelOngoingRequests = useCallback(() => {
        if (abortController) {
            abortController.abort();
            setAbortController(null);
        }
    }, [abortController]);

    const handleBackClick = useCallback(() => {
        cancelOngoingRequests();
        setShowChat(false);
        setShowTalk(false);
        setIsInChat(false);
        setActiveSection("Initial");

    }, [cancelOngoingRequests]);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    // Audio playback cleanup
    useEffect(() => {
        const handleAudioEnded = (event) => {
            if (event.target.src === audioPlaying) {
                setAudioPlaying(null);
            }
        };

        document.addEventListener("ended", handleAudioEnded, true);

        return () => {
            document.removeEventListener("ended", handleAudioEnded, true);
        };
    }, [audioPlaying]);


    //   useEffect(() => {
    //     let interval;
    //     if (recordingState === "Record") {
    //       interval = setInterval(() => {
    //         setRecordingDuration(prevDuration => prevDuration + 1);
    //       }, 1000);
    //     } else {
    //       setRecordingDuration(0);
    //     }
    //     return () => clearInterval(interval);
    //   }, [recordingState]);

    const toggleAudioPlayback = useCallback((audioUrl) => {
        if (audioPlaying === audioUrl) {
            // Pause the current audio
            if (currentAudio) {
                currentAudio.pause();
                setAudioPlaying(null);
            }
        } else {
            // Stop any currently playing audio
            if (currentAudio && audioPlaying) {
                currentAudio.pause();
            }

            const audio = document.getElementById(audioUrl) || new Audio(audioUrl);
            audio.onplay = () => setAudioPlaying(audioUrl);
            audio.onpause = () => setAudioPlaying(null);
            audio.onended = () => setAudioPlaying(null);
            audio.play().catch(e => console.error('Play error:', e));
            setCurrentAudio(audio);
        }
    }, [audioPlaying, currentAudio]);

    const playAudioAutomatically = useCallback((audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.onplay = () => setAudioPlaying(audioUrl);
        audio.onpause = () => setAudioPlaying(null);
        audio.onended = () => setAudioPlaying(null);
        audio.play().catch(e => console.error('Automatic play error:', e));
        setCurrentAudio(audio);
    }, []);


    return (
        // <ThemeProvider>
        <div className="fixed bottom-4 right-4 z-50 w-full sm:w-auto sm:max-w-sm max-w-[calc(100vw-2rem)]">
            {!isChatOpen && (
                <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: isChatOpen ? 0 : 1, y: isChatOpen ? 20 : 0 }}
                    transition={{ delay: isChatOpen ? 0 : 0.5 }}
                    className="ml-auto block bg-[#2888b5] text-white p-4 rounded-full shadow-lg"
                    onClick={toggleChat}
                >
                    <MdMessage size={24} />
                </motion.button>
            )}
            {/* <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover /> */}

            <AnimatePresence>
                {isChatOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        className="w-full sm:w-96 h-[80vh] sm:h-[32rem] flex flex-col rounded-lg shadow-xl relative bottom-0 right-0 md:bottom-16 sm:right-0"
                    >
                        {/* Chat header */}
                        <div className="flex justify-between items-center p-3 bg-[#2888b5] text-white rounded-t-lg">
                            {isInChat && (
                                <button
                                    onClick={handleBackClick}
                                    className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300"
                                >
                                    <RiIcons.RiArrowLeftLine size={18} />
                                </button>
                            )}
                            <h2 className="text-lg font-bold">Bolofy</h2>
                            <div className="flex space-x-2">
                                <div className="flex space-x-2">
                                                            {/* {(activeSection === "chat" || activeSection === "talk") && (
                                    <button onClick={toggleTheme} className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300">
                                    {theme === "light" ? <RiIcons.RiMoonLine size={18} /> : <RiIcons.RiSunLine size={18} />}
                                    </button>
                                )} */}
                                    <button onClick={toggleChat} className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-all duration-300">
                                        <RiIcons.RiCloseLine size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col overflow-hidden">
                            {!isInChat ? (
                                <>
                                    <div className="flex flex-col h-screen bg-white">
                                        <div className="flex-grow bg-gradient-to-t from-white to-[#2888b5]">
                                            <div className="flex-grow flex relative z-10 items-center justify-center mt-16">
                                                <motion.div
                                                    initial={{ scale: 0 }}
                                                    animate={{ scale: 1 }}
                                                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                                                    className="w-32 h-32 rounded-full bg-white shadow-lg overflow-hidden"
                                                >
                                                    <motion.img
                                                        src="https://i.ibb.co/k5gGX7J/bolofy-round.webp"
                                                        alt="Bolofy Logo"
                                                        className="w-full h-full object-contain"
                                                        style={{ scale: 1.05 }}
                                                        transition={{ duration: 0.3 }}
                                                    />
                                                </motion.div>
                                            </div>
                                        </div>
                                        <div className="px-4 pt-4 pb-2 m-2 bg-white">
                                            <AnimatedButtonLayout
                                                handleTalkClick={handleTalkClick}
                                                handleChatClick={handleChatClick}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* Chat messages area */}
                                    <div
                                        className="flex-1 overflow-y-auto p-4 space-y-4 bg-white custom-scrollbar"
                                        ref={chatBoxRef}
                                    >
                                        <AnimatePresence>
                                            {messages.map((message, index) => (
                                                <motion.div
                                                    key={index}
                                                    initial={{ opacity: 0, scale: 0.8, y: 50 }}
                                                    animate={{ opacity: 1, scale: 1, y: 0 }}
                                                    exit={{ opacity: 0, scale: 0.8, y: -50 }}
                                                    transition={{ type: "spring", stiffness: 100 }}
                                                    className={`flex ${message.type === "user" ? "justify-end" : "justify-start"}`}
                                                >
                                                    <div className={`flex items-start ${message.type === "user" ? null : "flex-row"} max-w-[80%] sm:max-w-[70%]`}>
                                                        <div className={`w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center ${message.type === "user" ? null : "bg-white mr-2"}`}
                                                        >
                                                            {message.type === "user" ?
                                                                null  // This will render nothing if message.type is "user"
                                                                :
                                                                (
                                                                    <img
                                                                        src="https://i.ibb.co/k5gGX7J/bolofy-round.webp"
                                                                        alt="Bolofy Logo"
                                                                        className="w-8 h-8 object-contain rounded-full"
                                                                    />
                                                                )
                                                            }

                                                        </div>

                                                        <div className={`p-3 mt-10 -ml-10 rounded-2xl ${message.type === "user" ? "bg-blue-100 text-blue-900" : " bg-sky-100 text-indigo-900"} ${theme === "dark" ? "text-gray-800" : "text-gray-800"} shadow-md break-words text-sm sm:text-base`}>
                                                            {message.isAudio ? (
                                                                <div className="flex flex-col">
                                                                    <div className="flex items-center">
                                                                        {/* <button
                                                                            onClick={() =>  toggleAudioPlayback(message.audioUrl)}
                                                                            className={`mr-2 p-2 bg-[#2888b5] text-white rounded-full  focus:outline-none focus:ring-2 focus:ring-yellow-400 ${message.type === 'bot' ? 'bg-[#2888b5]' : 'bg-[#368dff]'}`}
                                                                        >
                                                                            {audioPlaying === message.audioUrl ? (
                                                                                <RiIcons.RiPauseFill size={20} />
                                                                            ) : (
                                                                                <RiIcons.RiPlayFill size={20} />
                                                                            )}
                                                                        </button> */}
                                                                        {message.type === 'user' ? (
    <button
        onClick={() => toggleAudioPlayback(message.audioUrl)}
        className={`mr-2 p-2 bg-[#2888b5] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400 ${message.type === 'bot' ? 'bg-[#2888b5]' : 'bg-[#368dff]'}`}
    >
        {audioPlaying === message.audioUrl ? (
            <RiIcons.RiPauseFill size={20} />
        ) : (
            <RiIcons.RiPlayFill size={20} />
        )}
    </button>
) : (
    <button
        onClick={() => handleToggleSpeech(message.text, message.id)}
        className={`mr-2 p-2 bg-[#2888b5] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400 ${message.type === 'bot' ? 'bg-[#2888b5]' : 'bg-[#368dff]'}`}
    >
     {playingMessageId === message.id ? (
            <RiIcons.RiPauseFill size={20} />
        ) : (
            <RiIcons.RiPlayFill size={20} />
        )}
        {/* {currentSpeech && currentSpeech.messageId === message.id && isPlaying ? 'Pause' : 'Play'} */}
    </button>
)}
                                                                    {message.type === 'user' ? (
                                                                        <audio id={message.audioUrl} src={message.audioUrl} className="hidden" /> 
                                                                         ) : (                                
                                                                     <audio id={message.id} src={message.id} className="hidden" onEnded={() => setAudioPlaying(null)} />
                                                                    )}
                                                                        <span>{message.content}</span>
                                                                    </div>
                                                                    <div className="flex justify-between text-xs mt-1">
                                                                        <div className={`${message.type === 'bot' ? 'text-[#2888b5]' : 'text-[#368dff]'}`}>
                                                                            {message.audioDuration}
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {format(new Date(message.timestamp), "hh:mm a")}
                                                                        </div>
                                                                    </div>
                                                                    <div className=" text-gray-500">
                                                                        {message.text}

                                                                    </div>

                                                                </div>
                                                            ) : message.isRecording ? (
                                                                <div className="flex flex-col items-start space-y-1">
                                                                    <div className="flex items-center space-x-2">
                                                                        <SpectrumAnimation />
                                                                        <span>{message.content}</span>
                                                                    </div>
                                                                    <div className="text-xs text-gray-500">
                                                                        {Math.floor(recordingDuration / 60)}:
                                                                        {(recordingDuration % 60).toString().padStart(2, "0")}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                message.content
                                                            )}
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </AnimatePresence>
                                        {isTyping && (
                                            <motion.div
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                className="flex items-center text-gray-500 dark:text-gray-300"
                                            >
                                                <img
                                                    src="https://i.ibb.co/k5gGX7J/bolofy-round.webp"
                                                    alt="Bolofy Logo"
                                                    className="w-8 h-8 mr-3 object-contain rounded-full"
                                                />
                                                <LoadingSpinner />
                                            </motion.div>
                                        )}
                                    </div>

                                    {/* Input area */}
                                    <div className="p-2  bg-white shadow-top">
                                        {activeSection === "talk" && (

                                            <Talk
                                                setMessages={setMessages}
                                                isWaitingForResponse={isWaitingForResponse}
                                                setIsWaitingForResponse={setIsWaitingForResponse}
                                                recordingState={recordingState}
                                                setRecordingState={setRecordingState}
                                                recordingDuration={recordingDuration}
                                                setRecordingDuration={setRecordingDuration}
                                                speechDetected={speechDetected}
                                                setSpeechDetected={setSpeechDetected}
                                                setIsTyping={setIsTyping}
                                                setIsLoading={setIsLoading}
                                                setAbortController={setAbortController}
                                                playAudioAutomatically={playAudioAutomatically}
                                                // speakText={speakText}
                                                // isPlaying={isPlaying}
                                                currentSpeech={currentSpeech}
                                                // setIsPlaying={setIsPlaying}
                                                playingMessageId={playingMessageId}
                                                handleToggleSpeech={handleToggleSpeech}
                                            />
                                        )}
                                        {activeSection === "chat" && (
                                            <Chat
                                                setMessages={setMessages}
                                                isWaitingForResponse={isWaitingForResponse}
                                                setIsWaitingForResponse={setIsWaitingForResponse}
                                                setIsTyping={setIsTyping}
                                                setAbortController={setAbortController}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                            {/* Powered by Bolofy footer */}
                            <div className="p-2 bg-white text-gray-400 text-center text-xs rounded-b-lg">
                                Powered by Bolofy
                            </div>
                
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
        // </ThemeProvider>
    );
};

export default VoiceBot;