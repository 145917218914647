import React, { useState, useCallback, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import * as Fa6Icons from "react-icons/fa6";

import audioBufferToWav from 'audiobuffer-to-wav';
import axios from 'axios';

const Talk = ({   setMessages, isWaitingForResponse, 
    setIsWaitingForResponse,
    recordingState,
    setRecordingState,
    recordingDuration,
    setRecordingDuration,
    speechDetected,
    setSpeechDetected,
    setIsTyping,
    setIsLoading,
    setAbortController,
    speakText,
  
    playingMessageId,
    handleToggleSpeech,
    setIsPlaying,
    playAudioAutomatically
   }) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
//   const [recordingState, setRecordingState] = useState("Initial");
//   const [recordingDuration, setRecordingDuration] = useState(0);
//   const [isTyping, setIsTyping] = useState(false);
const [currentSpeech, setCurrentSpeech] = useState(null);
// const [isPlaying, setIsPlaying] = useState(false);
  const speechDetectedRef = useRef(false);
  const [theme, setTheme] = useState("light");
  const [audioURL, setAudioURL] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
//   const [speechDetected, setSpeechDetected] = useState(false);
  const [silenceTimer, setSilenceTimer] = useState(null);
  const [lastSpeechTime, setLastSpeechTime] = useState(null);
  const [isNoSpeechDetected, setIsNoSpeechDetected] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
//   const [abortController, setAbortController] = useState(null);
  const maxRecordingDuration = 15;
  const [audioCounter, setAudioCounter] = useState(1);
  const [talkTranscript, setTalkTranscript] = useState('');

  useEffect(() => {
    const ctx = new (window.AudioContext || window.webkitAudioContext)({ 
      sampleRate: 22050 
    });
    setAudioContext(ctx);
  }, []);



  useEffect(() => {
    if (recordingState === "Record") {
      resetSilenceTimer();
      if (transcript) {
        setSpeechDetected(true);
      }
    }
  }, [recordingState, transcript, resetSilenceTimer]);
    
  
  useEffect(() => {
    return () => {
      if (silenceTimer) {
        clearTimeout(silenceTimer);
      }
    };
  }, [silenceTimer]);
    
  useEffect(() => {
    if (recordingState === "Record" && transcript.trim() !== "") {
      setSpeechDetected(true);
      speechDetectedRef.current = true;
      console.log("Speech detected!");
    }
  }, [recordingState, transcript]);


  const resumeAudioContext = useCallback(() => {
    if (audioContext && audioContext.state === 'suspended') {
      audioContext.resume();
    }
  }, [audioContext]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `(${minutes}:${seconds.toString().padStart(2, '0')})`;
  };


  // Recording timer
 useEffect(() => {
  let interval;
  
  if (recordingState === "Record") {
    interval = setInterval(() => {
      setRecordingDuration((prevDuration) => {
        if (prevDuration >= maxRecordingDuration) {
          stopRecording();
          return maxRecordingDuration;
        }
        return prevDuration + 1;
      });
    }, 1000);
  } else {
    clearInterval(interval);
  }

  return () => clearInterval(interval);
}, [recordingState, stopRecording, setRecordingDuration]);
  

  const startRecording = useCallback(() => {
    console.log("Starting recording...");
    resumeAudioContext();
  
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
  
          recorder.ondataavailable = (e) => {
            const chunks = [];
            chunks.push(e.data);
            const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
            const url = window.URL.createObjectURL(blob);
            setAudioURL(url);
          };
  
          recorder.start();
          setRecordingState("Record");
          setRecordingDuration(0);
          setSpeechDetected(false);
          speechDetectedRef.current = false;
          setLastSpeechTime(Date.now());
  
          // Start silence detection
          const timer = setTimeout(() => {
            if (!speechDetected) {
              console.log("No speech detected, stopping recording...");
              stopRecording(); // Call stopRecording
            }
          }, 5000);
          setSilenceTimer(timer);
  
          SpeechRecognition.startListening({ continuous: true });
  
          const newMessage = {
            type: "user",
            content: "Recording in progress...",
            isRecording: true,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    }
  }, [resumeAudioContext, setMessages, speechDetected, stopRecording]);

  const stopRecording = useCallback(() => {
    console.log("Stopping recording...");
  
    console.log("Speech detected:", speechDetected);
  
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setRecordingState("Download");
      SpeechRecognition.stopListening();
  
      if (silenceTimer) {
        clearTimeout(silenceTimer);
        setSilenceTimer(null);
      }
  
      const noSpeechDetected = !speechDetectedRef.current;
      setIsNoSpeechDetected(noSpeechDetected);
  
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.isRecording
            ? {
                ...msg,
                content: noSpeechDetected ? "No speech detected. Recording stopped." : "Stopped voice recording.",
                isRecording: false,
                recordingDuration,
              }
            : msg
        )
      );
  
      if (noSpeechDetected) {
        setRecordingState("Initial");
      }
    } 
  }, [mediaRecorder, recordingDuration, silenceTimer]);


  
  const playAudioInTalk = useCallback(async (audioUrl) => {
    try {
      let audio = document.getElementById(audioUrl);
      if (!audio) {
        audio = new Audio(audioUrl);
        audio.id = audioUrl;
        document.body.appendChild(audio);
      }

      audio.oncanplaythrough = () => {
        audio.play().catch(e => console.error('Play error:', e));
        setAudioPlaying(audioUrl);
        setCurrentAudio(audio);
      };

      audio.onended = () => {
        setAudioPlaying(null);
        setCurrentAudio(null);
      };

      audio.onerror = (error) => {
        console.error('Error loading audio:', error);
        setAudioPlaying(null);
        setCurrentAudio(null);
      };

      // Add these event listeners
      audio.onplay = () => {
        setAudioPlaying(audioUrl);
        setCurrentAudio(audio);
      };
      audio.onpause = () => {
        setAudioPlaying(null);
        // Don't set currentAudio to null here to allow resuming
      };

    } catch (error) {
      console.error('Error in playAudioInTalk:', error);
    }
  }, []);
  
  // const sendTranscriptToBackend = useCallback(async (transcriptText) => {
  //   setIsLoading(true);
  //   setIsWaitingForResponse(true);
  
  //   const controller = new AbortController();
  //   setAbortController(controller);
  //   try {
  //     setIsTyping(true);
  
  //     let response;
  //     // Start of Selection
  //     try {
  //       response = await axios.post(
  //         "https://3516-49-36-67-16.ngrok-free.app/generate_audio",
  //         { text: transcriptText },
  //         {
  //           responseType: 'arraybuffer',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Accept': 'multipart/mixed',
  //           },
  //           signal: controller.signal,
  //           maxContentLength: Infinity,  // Set maxContentLength to Infinity to avoid RangeError
  //         }
  //       );
  //     } catch (error) {
  //       // Handle error here
  //       console.error("Error in sending transcript to backend:", error);
  //     }
  
  //     if (response.status === 200) {
  //       const contentType = response.headers['content-type'];
  //       const boundary = contentType.split('boundary=')[1];
        
  //       // Convert arraybuffer to string
  //       const responseText = new TextDecoder().decode(response.data);
        
  //       // Split the response into parts
  //       const parts = responseText.split(`--${boundary}`);
  
  //       let textResponse = '';
  //       let audioData = null;
  
  //       // Process each part
  //       try {
  //         parts.forEach(part => {
  //           if (part.includes('Content-Type: text/plain')) {
  //             textResponse = part.split('\r\n\r\n')[1].trim();
  //           } else if (part.includes('Content-Type: audio/wav')) {
  //             const audioStart = part.indexOf('\r\n\r\n') + 4;
  //             audioData = response.data.slice(audioStart);
  //           }
  //         });
  //       } catch (error) {
  //         console.error('Error processing response parts:', error);
  //       }
  
  //       // Process audio response
  //       if (audioData) {
  //         const audioBuffer = createAudioBufferFromRawData(audioData);
  
  //         // Convert AudioBuffer to WAV
  //         const wavBuffer = audioBufferToWav(audioBuffer);
  
  //         // Create a Blob from the WAV buffer
  //         const wavBlob = new Blob([new DataView(wavBuffer)], { type: 'audio/wav' });
  //         const audioUrl = URL.createObjectURL(wavBlob);
  
  //         const botAudioResponse = {
  //           type: "bot",
  //           content: "Bolofy Audio",
  //           isAudio: true,
  //           audioUrl: audioUrl,
  //           audioDuration: formatDuration(audioBuffer.duration),
  //           text: textResponse,
  //           timestamp: new Date(),
  //         };
  //         setMessages((prevMessages) => [...prevMessages, botAudioResponse]);
  
  //         await playAudioAutomatically(audioUrl);
  //       }
  
  //     } else {
  //       console.error('Error generating response:', response.status);
  //     }
  //   } catch (error) {
  //     console.error('Error sending transcript to backend:', error);
  //   } finally {
  //     setIsLoading(false);
  //     setIsTyping(false);
  //     setIsWaitingForResponse(false);
  //     setAbortController(null);
  //   }
  // }, [setIsLoading, setIsWaitingForResponse, setIsTyping, setMessages, playAudioAutomatically, formatDuration]);


  // const speakText = useCallback((text, messageId) => {
  //   const utterance = new SpeechSynthesisUtterance(text);
    
  //   utterance.onstart = () => {
  //     setIsPlaying(true);
  //     setCurrentSpeech({ utterance, messageId });
  //   };

  //   utterance.onend = () => {
  //     setIsPlaying(false);
  //     setCurrentSpeech(null);
  //   };

  //   utterance.onpause = () => setIsPlaying(false);
  //   utterance.onresume = () => setIsPlaying(true);

  //   window.speechSynthesis.speak(utterance);
  // }, []);

  // const handlehandleToggleSpeech = useCallback((text, messageId) => {
  //   if (currentSpeech && currentSpeech.messageId === messageId) {
  //     if (isPlaying) {
  //       window.speechSynthesis.pause();
  //       setIsPlaying(false);
  //     } else {
  //       window.speechSynthesis.resume();
  //       setIsPlaying(true);
  //     }
  //   } else {
  //     if (currentSpeech) {
  //       window.speechSynthesis.cancel();
  //     }
  //     const utterance = new SpeechSynthesisUtterance(text);
  //     utterance.onstart = () => {
  //       setIsPlaying(true);
  //       setCurrentSpeech({ utterance, messageId });
  //     };
  //     utterance.onend = () => {
  //       setIsPlaying(false);
  //       setCurrentSpeech(null);
  //     };
  //     utterance.onpause = () => setIsPlaying(false);
  //     utterance.onresume = () => setIsPlaying(true);
  //     window.speechSynthesis.speak(utterance);
  //   }
  // }, [currentSpeech, isPlaying]);

  const sendTranscriptToBackend = useCallback(async (transcriptText) => {
    setIsLoading(true);
    setIsWaitingForResponse(true);
  
    const controller = new AbortController();
    setAbortController(controller);
    try {
      setIsTyping(true);
  
      const response = await axios.post(
        "https://poc-python-chatbot-api.onrender.com/chat",
        { text: transcriptText },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal: controller.signal,
        }
      );
  
      if (response.status === 200) {
        const textResponse = response.data;
        const messageId = Date.now().toString();
        
        const botResponse = { 
          type: "bot", 
          content: "Bolofy Audio",
          text: textResponse, 
          isAudio: true,
          timestamp: new Date(),
          id: messageId,
          // isPlaying: false
        };

        setMessages((prevMessages) => [...prevMessages, botResponse]);

        // Automatically start speaking the response
        handleToggleSpeech(textResponse, messageId);
      } else {
        console.error('Error generating response:', response.status);
      }
    } catch (error) {
      console.error('Error sending transcript to backend:', error);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
      setIsWaitingForResponse(false);
      setAbortController(null);
    }
  }, [setIsLoading, setIsWaitingForResponse, setIsTyping, setMessages, handleToggleSpeech]);

  const sendAudioMessage = useCallback(() => {
    // Send the transcript to the backend
    sendTranscriptToBackend(transcript);
   
    const fileName = `audio_message_${audioCounter}.ogg`;
  
    setMessages((prevMessages) => {
      const stopRecordingIndex = prevMessages.findIndex(
        msg => msg.content === "Stopped voice recording."
      );
      
      if (stopRecordingIndex !== -1) {
        const newMessages = [...prevMessages];
        newMessages[stopRecordingIndex] = {
          type: "user",
          content: fileName,
          isAudio: true,
          audioUrl: audioURL,
          audioDuration: formatDuration(recordingDuration),
          timestamp: new Date(),
        };
        return newMessages;
      } else {
        return [...prevMessages, {
          type: "user",
          content: fileName,
          isAudio: true,
          audioUrl: audioURL,
          audioDuration: formatDuration(recordingDuration),
          timestamp: new Date(),
        }];
      }
    });
    
    setAudioCounter((prevCounter) => prevCounter + 1);
    setTalkTranscript('');
    resetTranscript();
    setRecordingState("Initial");
    setRecordingDuration(0);
  
  }, [audioCounter, audioURL, recordingDuration, transcript, resetTranscript, setMessages]);

  const toggleRecording = useCallback(() => {
    if (recordingState === "Initial" || recordingState === "Download") {
      startRecording();
    } else if (recordingState === "Record") {
      stopRecording();
    }
  }, [recordingState, startRecording, stopRecording]);

  const createAudioBufferFromRawData = (rawData, sampleRate = 22050) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const numberOfChannels = 1;
    const length = rawData.byteLength / 2;
    const audioBuffer = audioContext.createBuffer(numberOfChannels, length, sampleRate);
    const channelData = audioBuffer.getChannelData(0);

    const dv = new DataView(rawData);
    for (let i = 0; i < length; i++) {
      channelData[i] = dv.getInt16(i * 2, true) / 32768.0;
    }

    return audioBuffer;
  };

  const resetSilenceTimer = useCallback(() => {
    console.log("Resetting silence timer...");
    if (silenceTimer) {
      clearTimeout(silenceTimer);
    }
    const newTimer = setTimeout(() => {
      if (Date.now() - lastSpeechTime > 5000) {
        console.log("Silence detected, stopping recording...");
        stopRecording(); // Call stopRecording
      }
    }, 5000);
    setSilenceTimer(newTimer);
    setLastSpeechTime(Date.now());
  }, [silenceTimer, lastSpeechTime, stopRecording]);


  return (
    <div className={`p-2 rounded-b-lg ${theme === "dark" ? "bg-gray-800 text-black" : "bg-white text-gray-800"}`}>
      <div className="flex justify-center space-x-4">
        {recordingState !== "Download" ? (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            type="button"
            onClick={toggleRecording}
            disabled={isWaitingForResponse}
            className={`${
              recordingState === "Record"
                ? "bg-red-500 hover:bg-red-600"
                : "bg-[#2888b5] hover:bg-[#2888b581]"
            } text-white p-3 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300`}
          >
            {recordingState === "Record" ? (
              <Fa6Icons.FaRegCircleStop size={24} />
            ) : (
              <FaIcons.FaMicrophone size={24} />
            )}
          </motion.button>
        ) : (
          <>
            {!isNoSpeechDetected && (
              <>
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  type="button"
                  onClick={sendAudioMessage}
                  className="bg-[#2888B5] text-white p-3 rounded-full hover:bg-[#2888B581] focus:outline-none focus:ring-2 focus:ring-green-400 transition-all duration-300"
                >
                  <RiIcons.RiSendPlaneFill size={20} />
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  type="button"
                  onClick={() => {
                    setRecordingState("Initial");
                    setAudioURL("");
                    setMessages((prevMessages) => prevMessages.filter(msg => msg.content !== "Stopped voice recording."));
                  }}
                  className="bg-red-500 text-white p-3 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 transition-all duration-300"
                >
                  <RiIcons.RiCloseLine size={20} />
                </motion.button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Talk;