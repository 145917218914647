import React from 'react';
import { motion } from 'framer-motion';
import { FaMicrophone } from 'react-icons/fa';
import { LuMessagesSquare } from 'react-icons/lu';

const AnimatedButtonLayout = ({ handleTalkClick, handleChatClick }) => {
  const colors = {
    button1: "bg-[#2888b5] hover:bg-slate-700 focus:ring-indigo-500",
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },
    hover: { 
      scale: 1.05,
      y: -5,
      transition: { 
        duration: 0.3,
      },
    },
    tap: { scale: 0.98, y: 0 },
  };

  const iconVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.2,
      transition: { duration: 0.2 }
    },
  };

  const textVariants = {
    initial: { x: 0 },
    hover: { 
      x: 5,
      transition: { type: "spring", stiffness: 300 }
    },
  };

  return (
    <div>
      <motion.div
        className="flex space-x-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
 <motion.div className="w-1/2" variants={buttonVariants}>
          <motion.button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            onClick={handleTalkClick}
            className={`w-full h-10 rounded-3xl ${colors.button1} text-white font-semibold text-lg relative overflow-hidden transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            <div className="flex items-center justify-center space-x-2">
              <motion.div variants={iconVariants}>
                <FaMicrophone size={20} />
              </motion.div>
              <motion.span variants={textVariants}>Talk</motion.span>
            </div>
          </motion.button>
        </motion.div>
         
        <motion.div className="w-1/2" variants={buttonVariants}>
          <motion.button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            onClick={handleChatClick}
            className={`w-full h-10 rounded-3xl ${colors.button1} text-white font-semibold text-lg relative overflow-hidden transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            <div className="flex items-center justify-center space-x-2">
              <motion.div variants={iconVariants}>
                <LuMessagesSquare size={20} />
              </motion.div>
              <motion.span variants={textVariants}>Chat</motion.span>
            </div>
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AnimatedButtonLayout;